<script setup>
import { onMounted, onUnmounted } from "vue";

const handleAnimate = () => {
  const winH = window.innerHeight + 180;
  const winW = window.innerWidth + 180;
  const clientH = document.documentElement.clientHeight + 180;
  const clientW = document.documentElement.clientWidth + 180;

  const numSysData = document?.getElementById("number-system-data")?.getBoundingClientRect();
  if (
    numSysData &&
    numSysData.top >= 0 &&
    numSysData.left >= 0 &&
    numSysData.bottom <= (winH || clientH) &&
    numSysData.right <= (winW || clientW)
  ) {
    window.removeEventListener("scroll", handleAnimate);

    const counters = document.querySelectorAll(".counter-system-data");

    counters.forEach((counter) => {
      let count = 0;
      const updateCounter = () => {
        const countTarget = parseInt(counter.getAttribute("data-count"));

        const change = (countTarget - count) / 5;
        count += change >= 0 ? Math.ceil(change) : Math.floor(change);

        if (count < countTarget) {
          counter.innerHTML = count;
          setTimeout(updateCounter, 20);
        } else {
          counter.innerHTML = countTarget;
        }
      };
      updateCounter();
    });
  }
};

onMounted(() => window.addEventListener("scroll", handleAnimate));

onUnmounted(() => window.removeEventListener("scroll", handleAnimate));
</script>
<template>
  <div
    id="number-system-data"
    class="max-sm:mt-[20px] max-sm:flex-col-reverse sm:items-center gap-x-28 max-lg:gap-16 max-sm:gap-5"
  >
    <div class="mt-10 grid gap-8 grid-cols-4 max-md:grid-cols-2">
      <div class="max-sm:border-r-2 border-white">
        <div class="flex items-center">
          <h2 class="counter-system-data home-4-number max-sm:text-[40px]" data-count="17">0</h2>
          <div class="text-white heading-2">+</div>
        </div>
        <div class="w-full h-[1px] bg-white opacity-35 my-3 max-sm:hidden"></div>
        <div class="mt-2 font-normal text-white text-[16px]">Năm kinh nhiệm</div>
      </div>

      <div class="">
        <div class="flex items-center">
          <h2 class="counter-system-data home-4-number max-sm:text-[40px]" data-count="1000">0</h2>
          <div class="text-white heading-2">+</div>
        </div>
        <div class="w-full h-[1px] bg-white opacity-35 my-3 max-sm:hidden"></div>
        <div class="mt-2 font-normal text-white text-[16px]">Khách hàng trong và ngoài nước</div>
      </div>
      
      <div class="max-sm:border-r-2 border-white">
        <div class="flex items-center">
          <h2 class="counter-system-data home-4-number max-sm:text-[40px]" data-count="10">0</h2>
          <div class="text-white home-4-number">+</div>
        </div>
        <div class="w-full h-[1px] bg-white opacity-35 my-3 max-sm:hidden"></div>
        <div class="mt-2 font-normal text-white text-[16px]">Hệ thống</div>
      </div>

      <div class="">
        <div class="flex items-center">
          <h2 class="counter-system-data home-4-number max-sm:text-[40px]" data-count="10000">0</h2>
          <div class="text-white heading-2">+</div>
        </div>
        <div class="w-full h-[1px] bg-white opacity-35 my-3 max-sm:hidden"></div>
        <div class="mt-2 font-normal text-white text-[16px]">Điểm triển khai</div>
      </div>
    </div>
  </div>
</template>
