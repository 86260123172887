import { onMounted } from "vue";
import { delay } from "~/utils";

export const useBlog = (delayTime: number) => {
  const isVisibleBlog = ref(false);

  onMounted(async () => {
    await delay(delayTime);
    isVisibleBlog.value = true;
  });

  return { isVisibleBlog };
};
